





































































































































import { defineComponent, ref, Ref } from '@vue/composition-api';
import Card from '@/components/Card';
import FetchData from '@/components/FetchData';
import store from '@/store';
import { Empty } from '@/components/UI';

interface Contact {
	key?: string;
	keyShow?: string;
	value?: string;
	link?: string;
	//[propsName: string]: string | undefined;
}
interface FriendsData {
	key: string | number;
	nickname: string;
	avatar: string;
	description: string | undefined;
	content: string | undefined;
	qq: string | number | undefined;
	mail: string | undefined;
	url: string | undefined;
	otherContact?: Contact[];
}
interface Info {
	onlyid: string;
	title: string;
	description: string | undefined;
	content: string | undefined;
	img: string;
	isProtected: boolean;
	signal: string;
	answer: string;
}

export default defineComponent({
	components: { Card, FetchData, Empty },
	setup(props, { root }: any) {
		const { $route } = root;
		const t = root.$t.bind(root);
		//console.log($route.params.onlyid);
		const info: Ref<Info> = ref({
			onlyid: '',
			title: '',
			description: '',
			content: '',
			img: store.state.limitImgUrl,
			isProtected: false,
			signal: '',
			answer: '',
		});
		const friendsData: Ref<FriendsData[]> = ref([
			{
				key: 'key',
				nickname: '昵称占位',
				avatar: '/avatar.png',
				description: '简介占位',
				content: '内容占位',
				qq: 'QQ占位',
				mail: '邮箱占位',
				url: 'https://baidu.com/',
				otherContact: [],
			},
		]);
		const touchData = (data: any) => {
			info.value = data.info || { ...info.value };
			friendsData.value = data.member || [];
		};
		const clickLink = (event: Event, url: string | undefined) => {
			if (!url) {
				event?.preventDefault();
				return false;
			}
		};
		return { info, friendsData, touchData, clickLink, t };
	},
});
