import { defineComponent, ref, Ref } from '@vue/composition-api';

import Card from '@/components/Card';
import FetchData from '@/components/FetchData';
import PageCircleDetail from './page-circle-detail';
import { imgEsuv, imgIceb, imgSong, imgRbjy, imgYoga, imgYyip } from './img';

import type { VueConstructor } from 'vue';
import store from '@/store';
import { req } from '@/api';

interface GroupData {
	readonly onlyid: string | number;
	title: string;
	description?: string;
	img: VueConstructor<any> | string;
	isProtected?: boolean;
	signal?: string;
}

let isLoading = true;
export default defineComponent({
	components: { Card, FetchData, PageCircleDetail },
	setup(props, { root }: any) {
		const { $router, $message } = root;
		const t = root.$t.bind(root);
		const groupData: Ref<GroupData[]> = ref([
			{
				onlyid: 'd_38adfb59602382223551ee1af11609019fc07798',
				title: '标题占位符',
				description: '',
				img: store.state.limitImgUrl || '',
				isProtected: true,
				signal: '倩倩蕊蕊',
			},
			{
				onlyid: 'd_3d6827b0bda334337bb0ca9c4876ab9fa3151903',
				title: '汉服社',
				img: store.state.limitImgUrl || '',
				isProtected: true,
				signal: '齐',
			},
		]);
		const enterGroup = (onlyid: string): void => {
			if (isLoading) {
				$message.warning('加载中，请稍候再试');
				return;
			}
			const find = groupData.value.find(item => item.onlyid === onlyid);
			if (find && find.isProtected) {
				let answer = prompt(`您正在尝试访问圈子【${find.title}】，请回答以下问题：\r\n${find.signal}`);
				if (answer) {
					answer = '' + answer;
					req('LINK_CIRCLE_VALIDATE', { onlyid, answer })
						.then((data: any) => {
							if (data?.success) {
								$message.success('答对啦，进入圈子');
								$router.push(`/links/group/${onlyid}`).catch(() => {
									//
								});
							} else {
								throw data?.msg || '程序无法判断此错误';
							}
						})
						.catch(error => {
							//console.log('失败', error);
							$message.error('' + error);
						});
				} else {
					//$message.error('未输入任何内容');
				}
				return;
			}
			$router.push(`/links/group/${onlyid}`).catch(() => {
				//
			});
		};
		const touchData = (data: any) => {
			groupData.value = data;
			isLoading = false;
		};
		return { groupData, enterGroup, touchData, t };
	},
});
